import request from "./request";
import { host } from "../config/index";

export function post(url, params = {}, type = null) {
  return request({
    url: host + url,
    method: "POST",
    data: params,
    responseType: type ? type : null,
  });
}

export function get(url, params = {}, type = null) {
  return request({
    url: host + url,
    method: "GET",
    params: params,
    responseType: type ? type : null,
  });
}
