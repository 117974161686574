<template>
  <div class="header">
    <el-row>
      <el-col>
        <div class="header-content">
          <div class="content">
            <div class="title" @click="$router.push('/portal')">
              聆云报名系统
            </div>
            <div class="title-navigation">
              <div class="navigation-btn" @click="$router.push('/portal')">
                <img style="margin-bottom: -0.0208rem" src="../../assets/portal/index.png" alt="" />
                <span>首页</span>
              </div>
              <div class="navigation-btn" @click="$router.push('/portalPart/personalInformation')">
                <el-avatar :size="20" :src="state.image1Url" />
                <span style="margin-left: 0">{{ store.state.user.userId }}</span>
              </div>
              <div class="navigation-btn" @click="logout">
                <img style="margin-bottom: -0.0313rem" src="../../assets/portal/exit.png" alt="" />
                <span>退出</span>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script setup>
import { onMounted, reactive, ref } from "@vue/runtime-core";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import { post, get } from "@/api/api";
import { useStore } from "vuex";
let router = useRouter();
let store = useStore();
onMounted(() => {
  if (store.state.user.userInfo.photo) {
    state.image1Url = 'data:image/jpg;base64,' + store.state.user.userInfo.photo
  } else {
    getInfo()
  }
  // store.state.user.userId = window.sessionStorage.getItem("userId")
  // getInfo()
});
let state = reactive({ image1Url: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png' });

let logout = () => {
  ElMessageBox.confirm("确认退出当前用户吗?", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      store.commit("user/logout");
    })
    .catch(() => { });
};
// 获取个人信息
function getInfo () {
  get("/examApply/user/getInfo").then(res => {
    // state.image1Url = 'data:image/jpg;base64,' + res.result.photo
    store.commit('user/getUserinfo', res.result)
  })
}
</script>
<style lang="scss" scoped>
.header {
  height: 0.3125rem;
  line-height: 0.3125rem;
  overflow: hidden;
}

.header-content {
  height: 0.3125rem;
  line-height: 0.3125rem;
  //   margin-bottom: 20px;
  background-color: #005dc5;

  .content {
    padding: 0 20%;
  }

  .title {
    cursor: pointer;
    float: left;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #ffffff;
  }

  .title-navigation {
    float: right;

    .navigation-btn {
      display: inline-block;
      min-width: 0.4688rem;
      cursor: pointer;
      font-size: 0.0833rem;
      font-family: PingFang SC;
      font-weight: 500;
      text-align: center;
      color: #ffffff;

      img {
        width: 0.0938rem;
        height: 0.0938rem;
        margin-bottom: -2px;
      }

      span {
        font-size: 0.0833rem;
        margin: 0 5px;
        vertical-align: middle;
      }
    }

    .navigation-btn:hover {
      color: #e8c207;
    }
  }
}
</style>