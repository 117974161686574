let routers = [
  {
    path: "/:catchAll(.*)", 
    redirect: '/portal',
  },
  {
    path: "/",
    redirect: "/portal",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/portal",
    name: "Portal",
    component: () => import("@/views/portal/index.vue"),
  },
  {
    path: "/portalPart",
    name: "PortalPart",
    component: () => import("@/views/portal/components/index.vue"),
    children: [
      {
        path: "systemAnnouncement", // 系统公告
        component: () =>
          import("@/views/portal/pages/systemAnnouncement/index.vue"),
      },
      {
        path: "scoreQuery", // 成绩查询
        component: () => import("@/views/portal/components/scoreQuery.vue"),
      },
      {
        path: "messageNotification", // 消息通知
        component: () =>
          import("@/views/portal/components/messageNotification.vue"),
      },
      {
        path: "myRegistration", // 我的报考
        component: () => import("@/views/portal/components/myRegistration.vue"),
      },
      {
        path: "accountSecurity", // 账户安全
        component: () =>
          import("@/views/portal/components/accountSecurity.vue"),
      },
      {
        path: "candidateEntrance", // 考生入口
        name: "CandidateEntrance",
        component: () =>
          import("@/views/portal/pages/candidateEntrance/index.vue"),
      },
      {
        path: "personalInformation", // 个人信息
        component: () =>
          import("@/views/portal/pages/personalInformation/index.vue"),
      },
      {
        path: "examinationPermitPrinting", // 准考证打印
        component: () =>
          import("@/views/portal/pages/examinationPermitPrinting/index.vue"),
      },
    ],
  },
];

export default routers;
