import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import store from "../store/index.js";
NProgress.configure({ showSpinner: false });
axios.interceptors.request.use((config) => {
  let access_token = window.sessionStorage.getItem("access_token");
  let refresh_token = window.sessionStorage.getItem("refresh_token");
  let userId = window.sessionStorage.getItem("userId");
  if (access_token && refresh_token && userId) {
    config.headers["Authorization"] = access_token;
    config.headers["refreshToken"] = refresh_token;
    config.headers["userId"] = userId;
  }
  NProgress.start();
  return config;
});

axios.interceptors.response.use((config) => {
  NProgress.done();

  if (config.headers["authorization"]) {
    const new_access_token = config.headers["authorization"];
    window.sessionStorage.setItem("access_token", new_access_token);
  }

  if (config.data.code !== 200 && !config.data.size) {
    // ElMessage.error(config.data.message);
  }

  if (config.data.code === 401) {
    // store.commit("user/logout");
    window.sessionStorage.clear();
    // state.userId = "";
    window.location.reload();
  }
  return config.data;
});

export default axios;
