import router from "./index";
import NProgress from "nprogress";

let WhiteList = ["/login"];
router.beforeEach((to, form, next) => {
  let access_token = window.sessionStorage.getItem("access_token");
  let refresh_token = window.sessionStorage.getItem("refresh_token");
  let userId = window.sessionStorage.getItem("userId");

  NProgress.start();
  if (WhiteList.indexOf(to.path) === -1) {
    if (access_token && refresh_token && userId) {
      next();
    } else {
      next("/login");
    }
  } else {
    if (access_token && refresh_token && userId) {
      next("/");
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
